/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AdTrackingUrlSchemaV2
 */
export interface AdTrackingUrlSchemaV2 {
    /**
     * 
     * @type {string}
     * @memberof AdTrackingUrlSchemaV2
     */
    'hostType': AdTrackingUrlSchemaV2HostTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdTrackingUrlSchemaV2
     */
    'url': string;
}

export const AdTrackingUrlSchemaV2HostTypeEnum = {
    UNSPECIFIED: 'HOST_TYPE_UNSPECIFIED',
    INTERNAL: 'HOST_TYPE_INTERNAL',
    EXTERNAL: 'HOST_TYPE_EXTERNAL'
} as const;

export type AdTrackingUrlSchemaV2HostTypeEnum = typeof AdTrackingUrlSchemaV2HostTypeEnum[keyof typeof AdTrackingUrlSchemaV2HostTypeEnum];


