/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ShortformSearchSuccessResponseSearchComponentsInnerSearchItem } from './shortform-search-success-response-search-components-inner-search-item';
import { ShortformSearchSuccessResponseSearchComponentsInnerSearchItemGroup } from './shortform-search-success-response-search-components-inner-search-item-group';
import { ShortformSearchSuccessResponseSearchComponentsInnerSearchItemGroupCarContext } from './shortform-search-success-response-search-components-inner-search-item-group-car-context';
import { ShortformSearchSuccessResponseSearchComponentsInnerSearchItemGroupCommunityGroupContext } from './shortform-search-success-response-search-components-inner-search-item-group-community-group-context';
import { V3IntegrateSearchSuccessResponseShortformDocsInner } from './v3-integrate-search-success-response-shortform-docs-inner';

/**
 * 
 * @export
 * @interface LLMSearchStreamSuccessResponseSearchComponentsInnerData
 */
export interface LLMSearchStreamSuccessResponseSearchComponentsInnerData {
    /**
     * doc_id is elasticsearch document id.
     * @type {string}
     * @memberof LLMSearchStreamSuccessResponseSearchComponentsInnerData
     */
    'docId': string;
    /**
     * search item의 cursor
     * @type {string}
     * @memberof LLMSearchStreamSuccessResponseSearchComponentsInnerData
     */
    'nextDocToken': string;
    /**
     * search item의 종류를 나타내는 key. 예시: SEARCH_ITEM_TYPE_SHORTFORM인 경우, shortform 필드를 사용함
     * @type {string}
     * @memberof LLMSearchStreamSuccessResponseSearchComponentsInnerData
     */
    'searchItemKey': LLMSearchStreamSuccessResponseSearchComponentsInnerDataSearchItemKeyEnum;
    /**
     * 
     * @type {V3IntegrateSearchSuccessResponseShortformDocsInner}
     * @memberof LLMSearchStreamSuccessResponseSearchComponentsInnerData
     */
    'shortform'?: V3IntegrateSearchSuccessResponseShortformDocsInner;
    /**
     * 
     * @type {Array<ShortformSearchSuccessResponseSearchComponentsInnerSearchItem>}
     * @memberof LLMSearchStreamSuccessResponseSearchComponentsInnerData
     */
    'searchItems': Array<ShortformSearchSuccessResponseSearchComponentsInnerSearchItem>;
    /**
     * search item group의 종류를 나타내는 key. 예시: SEARCH_RESULT_GROUP_TYPE_SHORTFORM인 경우, shortformContext 필드를 사용함
     * @type {string}
     * @memberof LLMSearchStreamSuccessResponseSearchComponentsInnerData
     */
    'searchItemGroupType': LLMSearchStreamSuccessResponseSearchComponentsInnerDataSearchItemGroupTypeEnum;
    /**
     * search item group의 cursor
     * @type {string}
     * @memberof LLMSearchStreamSuccessResponseSearchComponentsInnerData
     */
    'nextToken': string;
    /**
     * 
     * @type {ShortformSearchSuccessResponseSearchComponentsInnerSearchItemGroupCommunityGroupContext}
     * @memberof LLMSearchStreamSuccessResponseSearchComponentsInnerData
     */
    'communityGroupContext'?: ShortformSearchSuccessResponseSearchComponentsInnerSearchItemGroupCommunityGroupContext;
    /**
     * 
     * @type {ShortformSearchSuccessResponseSearchComponentsInnerSearchItemGroupCarContext}
     * @memberof LLMSearchStreamSuccessResponseSearchComponentsInnerData
     */
    'carContext'?: ShortformSearchSuccessResponseSearchComponentsInnerSearchItemGroupCarContext;
    /**
     * 
     * @type {ShortformSearchSuccessResponseSearchComponentsInnerSearchItemGroupCommunityGroupContext}
     * @memberof LLMSearchStreamSuccessResponseSearchComponentsInnerData
     */
    'jobContext'?: ShortformSearchSuccessResponseSearchComponentsInnerSearchItemGroupCommunityGroupContext;
    /**
     * 
     * @type {ShortformSearchSuccessResponseSearchComponentsInnerSearchItemGroupCommunityGroupContext}
     * @memberof LLMSearchStreamSuccessResponseSearchComponentsInnerData
     */
    'realtyContext'?: ShortformSearchSuccessResponseSearchComponentsInnerSearchItemGroupCommunityGroupContext;
}

export const LLMSearchStreamSuccessResponseSearchComponentsInnerDataSearchItemKeyEnum = {
    UNSPECIFIED: 'SEARCH_ITEM_TYPE_UNSPECIFIED',
    FLEA_MARKET_ARTICLE: 'SEARCH_ITEM_TYPE_FLEA_MARKET_ARTICLE',
    AD: 'SEARCH_ITEM_TYPE_AD',
    NEIGHBOR_AD: 'SEARCH_ITEM_TYPE_NEIGHBOR_AD',
    RELATED_KEYWORD: 'SEARCH_ITEM_TYPE_RELATED_KEYWORD',
    USER: 'SEARCH_ITEM_TYPE_USER',
    COMMUNITY_GROUP: 'SEARCH_ITEM_TYPE_COMMUNITY_GROUP',
    COMMUNITY: 'SEARCH_ITEM_TYPE_COMMUNITY',
    LOCAL_PROFILE: 'SEARCH_ITEM_TYPE_LOCAL_PROFILE',
    BANNER: 'SEARCH_ITEM_TYPE_BANNER',
    JOB: 'SEARCH_ITEM_TYPE_JOB',
    HOME_AUTO_COMPLETE: 'SEARCH_ITEM_TYPE_HOME_AUTO_COMPLETE',
    CS_REPLY: 'SEARCH_ITEM_TYPE_CS_REPLY',
    HOT_KEYWORD: 'SEARCH_ITEM_TYPE_HOT_KEYWORD',
    REGION: 'SEARCH_ITEM_TYPE_REGION',
    SHORTFORM: 'SEARCH_ITEM_TYPE_SHORTFORM',
    BUSINESS_POST: 'SEARCH_ITEM_TYPE_BUSINESS_POST',
    CAR: 'SEARCH_ITEM_TYPE_CAR',
    REALTY: 'SEARCH_ITEM_TYPE_REALTY',
    COMMERCE: 'SEARCH_ITEM_TYPE_COMMERCE'
} as const;

export type LLMSearchStreamSuccessResponseSearchComponentsInnerDataSearchItemKeyEnum = typeof LLMSearchStreamSuccessResponseSearchComponentsInnerDataSearchItemKeyEnum[keyof typeof LLMSearchStreamSuccessResponseSearchComponentsInnerDataSearchItemKeyEnum];
export const LLMSearchStreamSuccessResponseSearchComponentsInnerDataSearchItemGroupTypeEnum = {
    UNSPECIFIED: 'SEARCH_RESULT_GROUP_TYPE_UNSPECIFIED',
    FLEA_MARKET: 'SEARCH_RESULT_GROUP_TYPE_FLEA_MARKET',
    COMMUNITY: 'SEARCH_RESULT_GROUP_TYPE_COMMUNITY',
    BUSINESS_POSTS: 'SEARCH_RESULT_GROUP_TYPE_BUSINESS_POSTS',
    PLACE: 'SEARCH_RESULT_GROUP_TYPE_PLACE',
    SHORTCUT: 'SEARCH_RESULT_GROUP_TYPE_SHORTCUT',
    JOB: 'SEARCH_RESULT_GROUP_TYPE_JOB',
    ADVERTISEMENT: 'SEARCH_RESULT_GROUP_TYPE_ADVERTISEMENT',
    COMMERCE: 'SEARCH_RESULT_GROUP_TYPE_COMMERCE',
    CAR: 'SEARCH_RESULT_GROUP_TYPE_CAR',
    REALTY: 'SEARCH_RESULT_GROUP_TYPE_REALTY',
    GROUP: 'SEARCH_RESULT_GROUP_TYPE_GROUP',
    LOCAL_PROFILE: 'SEARCH_RESULT_GROUP_TYPE_LOCAL_PROFILE',
    CATALOG_PRODUCT_AD: 'SEARCH_RESULT_GROUP_TYPE_CATALOG_PRODUCT_AD',
    SHORTFORM: 'SEARCH_RESULT_GROUP_TYPE_SHORTFORM',
    INTENT_CHIP: 'SEARCH_RESULT_GROUP_TYPE_INTENT_CHIP'
} as const;

export type LLMSearchStreamSuccessResponseSearchComponentsInnerDataSearchItemGroupTypeEnum = typeof LLMSearchStreamSuccessResponseSearchComponentsInnerDataSearchItemGroupTypeEnum[keyof typeof LLMSearchStreamSuccessResponseSearchComponentsInnerDataSearchItemGroupTypeEnum];


