/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ProtobufTimestamp } from './protobuf-timestamp';
import { SearchBigPicture } from './search-big-picture';
import { V3IntegrateSearchSuccessResponseCarDocsInnerCarDescription } from './v3-integrate-search-success-response-car-docs-inner-car-description';

/**
 * 
 * @export
 * @interface V3IntegrateSearchSuccessResponseCarDocsInner
 */
export interface V3IntegrateSearchSuccessResponseCarDocsInner {
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'displayPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'regionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'userStatus'?: V3IntegrateSearchSuccessResponseCarDocsInnerUserStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'carStatus'?: V3IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'carOptionNames'?: Array<V3IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum>;
    /**
     * 
     * @type {SearchBigPicture}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'firstImage'?: SearchBigPicture;
    /**
     * 
     * @type {V3IntegrateSearchSuccessResponseCarDocsInnerCarDescription}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'carDescription'?: V3IntegrateSearchSuccessResponseCarDocsInnerCarDescription;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'pullUpCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'chatCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'voteCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'viewCount'?: number;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'createdAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'updatedAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInner
     */
    'publishedAt'?: ProtobufTimestamp;
}

export const V3IntegrateSearchSuccessResponseCarDocsInnerUserStatusEnum = {
    UNSPECIFIED: 'CAR_USER_STATUS_UNSPECIFIED',
    WHITE: 'CAR_USER_STATUS_WHITE',
    NORMAL: 'CAR_USER_STATUS_NORMAL',
    DORMANCY: 'CAR_USER_STATUS_DORMANCY',
    SECESSION: 'CAR_USER_STATUS_SECESSION',
    BLOCKED: 'CAR_USER_STATUS_BLOCKED',
    BLACK: 'CAR_USER_STATUS_BLACK',
    UNKNOWN: 'CAR_USER_STATUS_UNKNOWN'
} as const;

export type V3IntegrateSearchSuccessResponseCarDocsInnerUserStatusEnum = typeof V3IntegrateSearchSuccessResponseCarDocsInnerUserStatusEnum[keyof typeof V3IntegrateSearchSuccessResponseCarDocsInnerUserStatusEnum];
export const V3IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum = {
    UNSPECIFIED: 'CAR_STATUS_UNSPECIFIED',
    SALE: 'CAR_STATUS_SALE',
    RESERVE: 'CAR_STATUS_RESERVE',
    TRADED: 'CAR_STATUS_TRADED'
} as const;

export type V3IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum = typeof V3IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum[keyof typeof V3IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum];
export const V3IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum = {
    UNSPECIFIED: 'CAR_OPTION_NAME_UNSPECIFIED',
    SMOKING_FREE: 'CAR_OPTION_NAME_SMOKING_FREE'
} as const;

export type V3IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum = typeof V3IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum[keyof typeof V3IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum];


