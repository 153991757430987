/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { V3IntegrateSearchSuccessResponseShortformDocsInner } from './v3-integrate-search-success-response-shortform-docs-inner';

/**
 * 콘텐츠의 최소단위, search component의 한 종류
 * @export
 * @interface ShortformSearchSuccessResponseSearchComponentsInnerSearchItem
 */
export interface ShortformSearchSuccessResponseSearchComponentsInnerSearchItem {
    /**
     * doc_id is elasticsearch document id.
     * @type {string}
     * @memberof ShortformSearchSuccessResponseSearchComponentsInnerSearchItem
     */
    'docId': string;
    /**
     * search item의 cursor
     * @type {string}
     * @memberof ShortformSearchSuccessResponseSearchComponentsInnerSearchItem
     */
    'nextDocToken': string;
    /**
     * search item의 종류를 나타내는 key. 예시: SEARCH_ITEM_TYPE_SHORTFORM인 경우, shortform 필드를 사용함
     * @type {string}
     * @memberof ShortformSearchSuccessResponseSearchComponentsInnerSearchItem
     */
    'searchItemKey': ShortformSearchSuccessResponseSearchComponentsInnerSearchItemSearchItemKeyEnum;
    /**
     * 
     * @type {V3IntegrateSearchSuccessResponseShortformDocsInner}
     * @memberof ShortformSearchSuccessResponseSearchComponentsInnerSearchItem
     */
    'shortform'?: V3IntegrateSearchSuccessResponseShortformDocsInner;
}

export const ShortformSearchSuccessResponseSearchComponentsInnerSearchItemSearchItemKeyEnum = {
    UNSPECIFIED: 'SEARCH_ITEM_TYPE_UNSPECIFIED',
    FLEA_MARKET_ARTICLE: 'SEARCH_ITEM_TYPE_FLEA_MARKET_ARTICLE',
    AD: 'SEARCH_ITEM_TYPE_AD',
    NEIGHBOR_AD: 'SEARCH_ITEM_TYPE_NEIGHBOR_AD',
    RELATED_KEYWORD: 'SEARCH_ITEM_TYPE_RELATED_KEYWORD',
    USER: 'SEARCH_ITEM_TYPE_USER',
    COMMUNITY_GROUP: 'SEARCH_ITEM_TYPE_COMMUNITY_GROUP',
    COMMUNITY: 'SEARCH_ITEM_TYPE_COMMUNITY',
    LOCAL_PROFILE: 'SEARCH_ITEM_TYPE_LOCAL_PROFILE',
    BANNER: 'SEARCH_ITEM_TYPE_BANNER',
    JOB: 'SEARCH_ITEM_TYPE_JOB',
    HOME_AUTO_COMPLETE: 'SEARCH_ITEM_TYPE_HOME_AUTO_COMPLETE',
    CS_REPLY: 'SEARCH_ITEM_TYPE_CS_REPLY',
    HOT_KEYWORD: 'SEARCH_ITEM_TYPE_HOT_KEYWORD',
    REGION: 'SEARCH_ITEM_TYPE_REGION',
    SHORTFORM: 'SEARCH_ITEM_TYPE_SHORTFORM',
    BUSINESS_POST: 'SEARCH_ITEM_TYPE_BUSINESS_POST',
    CAR: 'SEARCH_ITEM_TYPE_CAR',
    REALTY: 'SEARCH_ITEM_TYPE_REALTY',
    COMMERCE: 'SEARCH_ITEM_TYPE_COMMERCE'
} as const;

export type ShortformSearchSuccessResponseSearchComponentsInnerSearchItemSearchItemKeyEnum = typeof ShortformSearchSuccessResponseSearchComponentsInnerSearchItemSearchItemKeyEnum[keyof typeof ShortformSearchSuccessResponseSearchComponentsInnerSearchItemSearchItemKeyEnum];


