/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface V2FleamarketSchemaComponentNeighborAdOverflowsInnerAction
 */
export interface V2FleamarketSchemaComponentNeighborAdOverflowsInnerAction {
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentNeighborAdOverflowsInnerAction
     */
    'type'?: V2FleamarketSchemaComponentNeighborAdOverflowsInnerActionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentNeighborAdOverflowsInnerAction
     */
    'url'?: string;
}

export const V2FleamarketSchemaComponentNeighborAdOverflowsInnerActionTypeEnum = {
    UNSPECIFIED: 'OVERFLOW_ACTION_TYPE_UNSPECIFIED',
    POST: 'OVERFLOW_ACTION_TYPE_POST',
    TARGET: 'OVERFLOW_ACTION_TYPE_TARGET',
    HIDE: 'OVERFLOW_ACTION_TYPE_HIDE',
    HIDE_WITH_TARGET: 'OVERFLOW_ACTION_TYPE_HIDE_WITH_TARGET',
    DO_NOTHING: 'OVERFLOW_ACTION_TYPE_DO_NOTHING'
} as const;

export type V2FleamarketSchemaComponentNeighborAdOverflowsInnerActionTypeEnum = typeof V2FleamarketSchemaComponentNeighborAdOverflowsInnerActionTypeEnum[keyof typeof V2FleamarketSchemaComponentNeighborAdOverflowsInnerActionTypeEnum];


