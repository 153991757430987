/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ProtobufTimestamp } from './protobuf-timestamp';
import { RecentSearchQuerySearchQuery } from './recent-search-query-search-query';
import { V1Shortcut } from './v1-shortcut';

/**
 * 최근검색어 정보
 * @export
 * @interface RecentSearchQuery
 */
export interface RecentSearchQuery {
    /**
     * 
     * @type {string}
     * @memberof RecentSearchQuery
     */
    'type'?: RecentSearchQueryTypeEnum;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof RecentSearchQuery
     */
    'eventTime'?: ProtobufTimestamp;
    /**
     * 
     * @type {RecentSearchQuerySearchQuery}
     * @memberof RecentSearchQuery
     */
    'searchQuery'?: RecentSearchQuerySearchQuery;
    /**
     * 
     * @type {V1Shortcut}
     * @memberof RecentSearchQuery
     */
    'shortcut'?: V1Shortcut;
}

export const RecentSearchQueryTypeEnum = {
    UNSPECIFIED: 'RECENT_SEARCH_TYPE_UNSPECIFIED',
    QUERY: 'RECENT_SEARCH_TYPE_QUERY',
    SHORTCUT: 'RECENT_SEARCH_TYPE_SHORTCUT'
} as const;

export type RecentSearchQueryTypeEnum = typeof RecentSearchQueryTypeEnum[keyof typeof RecentSearchQueryTypeEnum];


