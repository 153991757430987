/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AdProductAdDetailSchemaV2 } from './ad-product-ad-detail-schema-v2';
import { AdSearchAdDetailSchemaV2 } from './ad-search-ad-detail-schema-v2';
import { AdTrackingUrlSchemaV2 } from './ad-tracking-url-schema-v2';
import { AdUserActionSchema } from './ad-user-action-schema';

/**
 * 
 * @export
 * @interface AdMediationSchemaV2
 */
export interface AdMediationSchemaV2 {
    /**
     * 
     * @type {string}
     * @memberof AdMediationSchemaV2
     */
    'dsp': AdMediationSchemaV2DspEnum;
    /**
     * 
     * @type {string}
     * @memberof AdMediationSchemaV2
     */
    'idInDsp': string;
    /**
     * hash = dsp:id_in_dsp
     * @type {string}
     * @memberof AdMediationSchemaV2
     */
    'hash': string;
    /**
     * 외부 광고일 경우 overflow 최상단에 locallized_ad_source_name의 광고임을 표기해야 합니다.
     * @type {string}
     * @memberof AdMediationSchemaV2
     */
    'localizedAdSourceName': string;
    /**
     * 
     * @type {string}
     * @memberof AdMediationSchemaV2
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdMediationSchemaV2
     */
    'thumbnailUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdMediationSchemaV2
     */
    'targetUrl': string;
    /**
     * 
     * @type {Array<AdTrackingUrlSchemaV2>}
     * @memberof AdMediationSchemaV2
     */
    'impressionTrackingUrls': Array<AdTrackingUrlSchemaV2>;
    /**
     * 
     * @type {Array<AdTrackingUrlSchemaV2>}
     * @memberof AdMediationSchemaV2
     */
    'clickTrackingUrls': Array<AdTrackingUrlSchemaV2>;
    /**
     * 
     * @type {Array<AdUserActionSchema>}
     * @memberof AdMediationSchemaV2
     */
    'userActions': Array<AdUserActionSchema>;
    /**
     * 
     * @type {AdProductAdDetailSchemaV2}
     * @memberof AdMediationSchemaV2
     */
    'productAdDetail'?: AdProductAdDetailSchemaV2;
    /**
     * 
     * @type {AdSearchAdDetailSchemaV2}
     * @memberof AdMediationSchemaV2
     */
    'searchAdDetail'?: AdSearchAdDetailSchemaV2;
}

export const AdMediationSchemaV2DspEnum = {
    UNSPECIFIED: 'DSP_UNSPECIFIED',
    KARROT: 'DSP_KARROT',
    NAVER: 'DSP_NAVER',
    COUPANG: 'DSP_COUPANG',
    ELEVENST: 'DSP_ELEVENST',
    EDNPLUS: 'DSP_EDNPLUS'
} as const;

export type AdMediationSchemaV2DspEnum = typeof AdMediationSchemaV2DspEnum[keyof typeof AdMediationSchemaV2DspEnum];


