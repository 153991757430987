/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ApiV1PlaceSearchPostRequestMapLocation } from './api-v1-place-search-post-request-map-location';
import { Location } from './location';

/**
 * 
 * @export
 * @interface ApiV1PlaceSearchPostRequest
 */
export interface ApiV1PlaceSearchPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiV1PlaceSearchPostRequest
     */
    'query': string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1PlaceSearchPostRequest
     */
    'regionId': string;
    /**
     * 
     * @type {number}
     * @memberof ApiV1PlaceSearchPostRequest
     */
    'pageSize': number;
    /**
     * 
     * @type {string}
     * @memberof ApiV1PlaceSearchPostRequest
     */
    'pageToken'?: string;
    /**
     * 전체 페이지의 마지막 index
     * @type {number}
     * @memberof ApiV1PlaceSearchPostRequest
     */
    'lastIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiV1PlaceSearchPostRequest
     */
    'sort'?: ApiV1PlaceSearchPostRequestSortEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApiV1PlaceSearchPostRequest
     */
    'useLocation': boolean;
    /**
     * 
     * @type {Location}
     * @memberof ApiV1PlaceSearchPostRequest
     */
    'location'?: Location;
    /**
     * 
     * @type {boolean}
     * @memberof ApiV1PlaceSearchPostRequest
     */
    'useMap': boolean;
    /**
     * 
     * @type {ApiV1PlaceSearchPostRequestMapLocation}
     * @memberof ApiV1PlaceSearchPostRequest
     */
    'mapLocation'?: ApiV1PlaceSearchPostRequestMapLocation;
}

export const ApiV1PlaceSearchPostRequestSortEnum = {
    UNSPECIFIED: 'SEARCH_SORT_UNSPECIFIED',
    RELEVANT: 'SEARCH_SORT_RELEVANT',
    DISTANCE: 'SEARCH_SORT_DISTANCE'
} as const;

export type ApiV1PlaceSearchPostRequestSortEnum = typeof ApiV1PlaceSearchPostRequestSortEnum[keyof typeof ApiV1PlaceSearchPostRequestSortEnum];


