/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ProtobufTimestamp } from './protobuf-timestamp';
import { SearchBigPicture } from './search-big-picture';
import { V2FleamarketSchemaComponentNeighborAdInfo } from './v2-fleamarket-schema-component-neighbor-ad-info';
import { V2FleamarketSchemaComponentNeighborAdInfoButtonLogging } from './v2-fleamarket-schema-component-neighbor-ad-info-button-logging';
import { V2FleamarketSchemaComponentNeighborAdOverflowsInner } from './v2-fleamarket-schema-component-neighbor-ad-overflows-inner';

/**
 * 
 * @export
 * @interface V2FleamarketSchemaComponentNeighborAd
 */
export interface V2FleamarketSchemaComponentNeighborAd {
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'slot': string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'targetUri': string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'regionId': string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'regionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'firstImageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'status'?: V2FleamarketSchemaComponentNeighborAdStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'watchCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'chatRoomCount'?: number;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'publishedAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'createdAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {SearchBigPicture}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'firstImage'?: SearchBigPicture;
    /**
     * 
     * @type {V2FleamarketSchemaComponentNeighborAdInfo}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'info'?: V2FleamarketSchemaComponentNeighborAdInfo;
    /**
     * 
     * @type {Array<V2FleamarketSchemaComponentNeighborAdOverflowsInner>}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'overflows'?: Array<V2FleamarketSchemaComponentNeighborAdOverflowsInner>;
    /**
     * 
     * @type {V2FleamarketSchemaComponentNeighborAdInfoButtonLogging}
     * @memberof V2FleamarketSchemaComponentNeighborAd
     */
    'logging'?: V2FleamarketSchemaComponentNeighborAdInfoButtonLogging;
}

export const V2FleamarketSchemaComponentNeighborAdStatusEnum = {
    UNSPECIFIED: 'NEIGHBOR_AD_STATUS_UNSPECIFIED',
    ONGOING: 'NEIGHBOR_AD_STATUS_ONGOING',
    RESERVED: 'NEIGHBOR_AD_STATUS_RESERVED',
    CLOSED: 'NEIGHBOR_AD_STATUS_CLOSED'
} as const;

export type V2FleamarketSchemaComponentNeighborAdStatusEnum = typeof V2FleamarketSchemaComponentNeighborAdStatusEnum[keyof typeof V2FleamarketSchemaComponentNeighborAdStatusEnum];


