/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AdConversionSchema } from './ad-conversion-schema';

/**
 * 
 * @export
 * @interface AdSearchAdDetailSchemaV2
 */
export interface AdSearchAdDetailSchemaV2 {
    /**
     * 
     * @type {string}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'categoryName': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'hasCoupon': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'isReservable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'isPurchasable': boolean;
    /**
     * 
     * @type {Array<AdConversionSchema>}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'conversions': Array<AdConversionSchema>;
    /**
     * 
     * @type {string}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'starRating'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'regionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'deliberationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'businessAccountName': string;
    /**
     * 
     * @type {string}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'operationStatusType'?: AdSearchAdDetailSchemaV2OperationStatusTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'labels': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'businessThumbnailImage': string;
    /**
     * 
     * @type {string}
     * @memberof AdSearchAdDetailSchemaV2
     */
    'businessName': string;
}

export const AdSearchAdDetailSchemaV2OperationStatusTypeEnum = {
    UNSPECIFIED: 'OPERATION_STATUS_TYPE_UNSPECIFIED',
    OPEN: 'OPERATION_STATUS_TYPE_OPEN',
    CLOSED_FOR_PREPARATION: 'OPERATION_STATUS_TYPE_CLOSED_FOR_PREPARATION',
    BREAK_TIME: 'OPERATION_STATUS_TYPE_BREAK_TIME',
    CLOSED_TODAY: 'OPERATION_STATUS_TYPE_CLOSED_TODAY',
    OPEN_24_HOURS: 'OPERATION_STATUS_TYPE_OPEN_24_HOURS'
} as const;

export type AdSearchAdDetailSchemaV2OperationStatusTypeEnum = typeof AdSearchAdDetailSchemaV2OperationStatusTypeEnum[keyof typeof AdSearchAdDetailSchemaV2OperationStatusTypeEnum];


