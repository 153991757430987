/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ApiV1BizPostSearchPostRequest
 */
export interface ApiV1BizPostSearchPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiV1BizPostSearchPostRequest
     */
    'query': string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1BizPostSearchPostRequest
     */
    'regionId': string;
    /**
     * 
     * @type {number}
     * @memberof ApiV1BizPostSearchPostRequest
     */
    'pageSize': number;
    /**
     * 
     * @type {string}
     * @memberof ApiV1BizPostSearchPostRequest
     */
    'pageToken'?: string;
    /**
     * 전체 페이지의 마지막 index
     * @type {number}
     * @memberof ApiV1BizPostSearchPostRequest
     */
    'lastIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiV1BizPostSearchPostRequest
     */
    'sort'?: ApiV1BizPostSearchPostRequestSortEnum;
}

export const ApiV1BizPostSearchPostRequestSortEnum = {
    UNSPECIFIED: 'SEARCH_SORT_UNSPECIFIED',
    RELEVANT: 'SEARCH_SORT_RELEVANT',
    RECENT: 'SEARCH_SORT_RECENT'
} as const;

export type ApiV1BizPostSearchPostRequestSortEnum = typeof ApiV1BizPostSearchPostRequestSortEnum[keyof typeof ApiV1BizPostSearchPostRequestSortEnum];


