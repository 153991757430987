/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ProtobufTimestamp } from './protobuf-timestamp';
import { SearchBigPicture } from './search-big-picture';
import { V3IntegrateSearchSuccessResponseJobDocsInnerWorkplaceLocationCoordinates } from './v3-integrate-search-success-response-job-docs-inner-workplace-location-coordinates';

/**
 * 
 * @export
 * @interface V3IntegrateSearchSuccessResponseJobDocsInner
 */
export interface V3IntegrateSearchSuccessResponseJobDocsInner {
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'content': string;
    /**
     * 
     * @type {Array<SearchBigPicture>}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'images'?: Array<SearchBigPicture>;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'regionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'salaryType'?: V3IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'salary'?: number;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'workPeriod'?: V3IntegrateSearchSuccessResponseJobDocsInnerWorkPeriodEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'workDays'?: Array<V3IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'isWorkPeriodNegotiable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'isWorkTimeNegotiable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'isWorkDaysNegotiable'?: boolean;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'publishedAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'lastBringUpDate'?: ProtobufTimestamp;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'targetUri'?: string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'globalId': string;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'jobReviewCount'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'authorBadges'?: Array<string>;
    /**
     * 
     * @type {V3IntegrateSearchSuccessResponseJobDocsInnerWorkplaceLocationCoordinates}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'workplaceLocationCoordinates'?: V3IntegrateSearchSuccessResponseJobDocsInnerWorkplaceLocationCoordinates;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'advertisementLivenessCheckedAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {Array<string>}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'advertisementRegionIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'startWorkTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'endWorkTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'index'?: number;
    /**
     * 
     * @type {Array<ProtobufTimestamp>}
     * @memberof V3IntegrateSearchSuccessResponseJobDocsInner
     */
    'workDates'?: Array<ProtobufTimestamp>;
}

export const V3IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum = {
    UNSPECIFIED: 'JOB_SALARY_TYPE_UNSPECIFIED',
    DAILY: 'JOB_SALARY_TYPE_DAILY',
    HOURLY: 'JOB_SALARY_TYPE_HOURLY',
    MONTHLY: 'JOB_SALARY_TYPE_MONTHLY',
    PER_CASE: 'JOB_SALARY_TYPE_PER_CASE'
} as const;

export type V3IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum = typeof V3IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum[keyof typeof V3IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum];
export const V3IntegrateSearchSuccessResponseJobDocsInnerWorkPeriodEnum = {
    UNSPECIFIED: 'JOB_WORK_PERIOD_UNSPECIFIED',
    LESS_THAN_A_MONTH: 'JOB_WORK_PERIOD_LESS_THAN_A_MONTH',
    MORE_THAN_A_MONTH: 'JOB_WORK_PERIOD_MORE_THAN_A_MONTH'
} as const;

export type V3IntegrateSearchSuccessResponseJobDocsInnerWorkPeriodEnum = typeof V3IntegrateSearchSuccessResponseJobDocsInnerWorkPeriodEnum[keyof typeof V3IntegrateSearchSuccessResponseJobDocsInnerWorkPeriodEnum];
export const V3IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum = {
    UNSPECIFIED: 'JOB_WORK_DAY_UNSPECIFIED',
    MON: 'JOB_WORK_DAY_MON',
    TUE: 'JOB_WORK_DAY_TUE',
    WED: 'JOB_WORK_DAY_WED',
    THU: 'JOB_WORK_DAY_THU',
    FRI: 'JOB_WORK_DAY_FRI',
    SAT: 'JOB_WORK_DAY_SAT',
    SUN: 'JOB_WORK_DAY_SUN'
} as const;

export type V3IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum = typeof V3IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum[keyof typeof V3IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum];


