/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface V3IntegrateSearchSuccessResponseCarDocsInnerCarDescription
 */
export interface V3IntegrateSearchSuccessResponseCarDocsInnerCarDescription {
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInnerCarDescription
     */
    'driveDistance'?: number;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInnerCarDescription
     */
    'modelYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInnerCarDescription
     */
    'carName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V3IntegrateSearchSuccessResponseCarDocsInnerCarDescription
     */
    'changeHistories'?: Array<V3IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum>;
}

export const V3IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum = {
    UNSPECIFIED: 'CAR_CHANGE_HISTORY_TYPE_UNSPECIFIED',
    REGISTER: 'CAR_CHANGE_HISTORY_TYPE_REGISTER',
    CAR_NO: 'CAR_CHANGE_HISTORY_TYPE_CAR_NO',
    OWNER: 'CAR_CHANGE_HISTORY_TYPE_OWNER'
} as const;

export type V3IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum = typeof V3IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum[keyof typeof V3IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum];


