/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface V2FleamarketSchemaComponentRelatedKeywordKeywordsInner
 */
export interface V2FleamarketSchemaComponentRelatedKeywordKeywordsInner {
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentRelatedKeywordKeywordsInner
     */
    'type'?: V2FleamarketSchemaComponentRelatedKeywordKeywordsInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentRelatedKeywordKeywordsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentRelatedKeywordKeywordsInner
     */
    'targetUri'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentRelatedKeywordKeywordsInner
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentRelatedKeywordKeywordsInner
     */
    'categoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentRelatedKeywordKeywordsInner
     */
    'customText'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentRelatedKeywordKeywordsInner
     */
    'bgColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentRelatedKeywordKeywordsInner
     */
    'customTextDisplay'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentRelatedKeywordKeywordsInner
     */
    'shortcutId'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentRelatedKeywordKeywordsInner
     */
    'decoration'?: string;
}

export const V2FleamarketSchemaComponentRelatedKeywordKeywordsInnerTypeEnum = {
    UNSPECIFIED: 'KEYWORD_TYPE_UNSPECIFIED',
    KEYWORD: 'KEYWORD_TYPE_KEYWORD',
    SHORTCUT: 'KEYWORD_TYPE_SHORTCUT'
} as const;

export type V2FleamarketSchemaComponentRelatedKeywordKeywordsInnerTypeEnum = typeof V2FleamarketSchemaComponentRelatedKeywordKeywordsInnerTypeEnum[keyof typeof V2FleamarketSchemaComponentRelatedKeywordKeywordsInnerTypeEnum];


