/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ErrorDetailSchemaTitle } from './error-detail-schema-title';

/**
 * 
 * @export
 * @interface ErrorDetailSchema
 */
export interface ErrorDetailSchema {
    /**
     * 
     * @type {string}
     * @memberof ErrorDetailSchema
     */
    'type': ErrorDetailSchemaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetailSchema
     */
    'displayType': ErrorDetailSchemaDisplayTypeEnum;
    /**
     * 
     * @type {ErrorDetailSchemaTitle}
     * @memberof ErrorDetailSchema
     */
    'title'?: ErrorDetailSchemaTitle;
    /**
     * 
     * @type {ErrorDetailSchemaTitle}
     * @memberof ErrorDetailSchema
     */
    'message'?: ErrorDetailSchemaTitle;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetailSchema
     */
    'metadataProtoJson'?: string;
}

export const ErrorDetailSchemaTypeEnum = {
    UNSPECIFIED: 'ERROR_TYPE_UNSPECIFIED',
    LONG_TAIL_QUERY: 'ERROR_TYPE_LONG_TAIL_QUERY',
    NULL_QUERY: 'ERROR_TYPE_NULL_QUERY',
    NULL_REGION_ID: 'ERROR_TYPE_NULL_REGION_ID',
    UNAUTHENTICATED: 'ERROR_TYPE_UNAUTHENTICATED',
    ABUSER_DETECTED: 'ERROR_TYPE_ABUSER_DETECTED',
    BANNED_KEYWORD: 'ERROR_TYPE_BANNED_KEYWORD',
    INVALID_CURSOR: 'ERROR_TYPE_INVALID_CURSOR'
} as const;

export type ErrorDetailSchemaTypeEnum = typeof ErrorDetailSchemaTypeEnum[keyof typeof ErrorDetailSchemaTypeEnum];
export const ErrorDetailSchemaDisplayTypeEnum = {
    UNSPECIFIED: 'DISPLAY_TYPE_UNSPECIFIED',
    SNACKBAR: 'DISPLAY_TYPE_SNACKBAR',
    DIALOG: 'DISPLAY_TYPE_DIALOG',
    NONE: 'DISPLAY_TYPE_NONE'
} as const;

export type ErrorDetailSchemaDisplayTypeEnum = typeof ErrorDetailSchemaDisplayTypeEnum[keyof typeof ErrorDetailSchemaDisplayTypeEnum];


