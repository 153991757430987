/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ApiInternalTestLlmSearchStreamPostRequest
 */
export interface ApiInternalTestLlmSearchStreamPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiInternalTestLlmSearchStreamPostRequest
     */
    'query': string;
    /**
     * 
     * @type {string}
     * @memberof ApiInternalTestLlmSearchStreamPostRequest
     */
    'regionId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiInternalTestLlmSearchStreamPostRequest
     */
    'clientType': ApiInternalTestLlmSearchStreamPostRequestClientTypeEnum;
}

export const ApiInternalTestLlmSearchStreamPostRequestClientTypeEnum = {
    UNSPECIFIED: 'CLIENT_TYPE_UNSPECIFIED',
    FLEA_MARKET_VECTOR_SEARCH: 'CLIENT_TYPE_FLEA_MARKET_VECTOR_SEARCH',
    FLEA_MARKET_RELEVANT: 'CLIENT_TYPE_FLEA_MARKET_RELEVANT',
    FLEA_MARKET_RECENT: 'CLIENT_TYPE_FLEA_MARKET_RECENT',
    USER_RELEVANT: 'CLIENT_TYPE_USER_RELEVANT',
    COMMUNITY_GROUP_RECOMMENDED: 'CLIENT_TYPE_COMMUNITY_GROUP_RECOMMENDED',
    COMMUNITY_GROUP_RECENT: 'CLIENT_TYPE_COMMUNITY_GROUP_RECENT',
    COMMUNITY_GROUP_POPULAR: 'CLIENT_TYPE_COMMUNITY_GROUP_POPULAR',
    BUSINESS_POST_RELEVANT: 'CLIENT_TYPE_BUSINESS_POST_RELEVANT',
    BUSINESS_POST_RECENT: 'CLIENT_TYPE_BUSINESS_POST_RECENT',
    COMMUNITY_RELEVANT: 'CLIENT_TYPE_COMMUNITY_RELEVANT',
    COMMUNITY_RECENT: 'CLIENT_TYPE_COMMUNITY_RECENT',
    LOCAL_PROFILE_RELEVANT: 'CLIENT_TYPE_LOCAL_PROFILE_RELEVANT',
    LOCAL_PROFILE_DISTANCE: 'CLIENT_TYPE_LOCAL_PROFILE_DISTANCE',
    ADS_PRODUCT: 'CLIENT_TYPE_ADS_PRODUCT',
    ADDRESS_AUTO_COMPLETE: 'CLIENT_TYPE_ADDRESS_AUTO_COMPLETE',
    FLEA_MARKET_CURATION_ARTICLE_RECENT: 'CLIENT_TYPE_FLEA_MARKET_CURATION_ARTICLE_RECENT',
    FLEA_MARKET_CURATION_ARTICLE_RELEVANT: 'CLIENT_TYPE_FLEA_MARKET_CURATION_ARTICLE_RELEVANT',
    FLEA_MARKET_CURATION_ARTICLE_SCORE: 'CLIENT_TYPE_FLEA_MARKET_CURATION_ARTICLE_SCORE',
    JOB_RELEVANT: 'CLIENT_TYPE_JOB_RELEVANT',
    FLEA_MARKET_FILTER_RECOMMEND: 'CLIENT_TYPE_FLEA_MARKET_FILTER_RECOMMEND',
    CS_REPLY: 'CLIENT_TYPE_CS_REPLY',
    JOB_RECOMMENDED: 'CLIENT_TYPE_JOB_RECOMMENDED',
    JOB_CONDITIONAL: 'CLIENT_TYPE_JOB_CONDITIONAL',
    HOME_AUTO_COMPLETE: 'CLIENT_TYPE_HOME_AUTO_COMPLETE',
    FLEA_MARKET_RELEVANT_WEB: 'CLIENT_TYPE_FLEA_MARKET_RELEVANT_WEB',
    FLEA_MARKET_RECENT_WEB: 'CLIENT_TYPE_FLEA_MARKET_RECENT_WEB',
    REGION_NAME: 'CLIENT_TYPE_REGION_NAME',
    COMMUNITY_RELEVANT_WEB: 'CLIENT_TYPE_COMMUNITY_RELEVANT_WEB',
    COMMUNITY_RECENT_WEB: 'CLIENT_TYPE_COMMUNITY_RECENT_WEB',
    LOCAL_MAP_RELEVANT: 'CLIENT_TYPE_LOCAL_MAP_RELEVANT',
    FLEA_MARKET_CURATION_ARTICLE_RECENT_INTERNAL: 'CLIENT_TYPE_FLEA_MARKET_CURATION_ARTICLE_RECENT_INTERNAL',
    FLEA_MARKET_CURATION_ARTICLE_RELEVANT_INTERNAL: 'CLIENT_TYPE_FLEA_MARKET_CURATION_ARTICLE_RELEVANT_INTERNAL',
    FLEA_MARKET_CURATION_ARTICLE_SCORE_INTERNAL: 'CLIENT_TYPE_FLEA_MARKET_CURATION_ARTICLE_SCORE_INTERNAL',
    COMMUNITY_GROUP_RECOMMENDED_WEB: 'CLIENT_TYPE_COMMUNITY_GROUP_RECOMMENDED_WEB',
    COMMUNITY_GROUP_POPULAR_WEB: 'CLIENT_TYPE_COMMUNITY_GROUP_POPULAR_WEB',
    SHORTFORM_RELEVANT: 'CLIENT_TYPE_SHORTFORM_RELEVANT',
    SHORTFORM_DISTANCE: 'CLIENT_TYPE_SHORTFORM_DISTANCE',
    FLEA_MARKET_CATEGORY_HOME_RECENT: 'CLIENT_TYPE_FLEA_MARKET_CATEGORY_HOME_RECENT',
    LOCAL_PROFILE_RELEVANT_WEB: 'CLIENT_TYPE_LOCAL_PROFILE_RELEVANT_WEB',
    FLEA_MARKET_RELEVANT_FOR_CURATION_ARTICLE: 'CLIENT_TYPE_FLEA_MARKET_RELEVANT_FOR_CURATION_ARTICLE',
    FLEA_MARKET_RECENT_FOR_CURATION_ARTICLE: 'CLIENT_TYPE_FLEA_MARKET_RECENT_FOR_CURATION_ARTICLE',
    RETRIEVAL_ARGUMENT_GENERATE_COMMUNITY: 'CLIENT_TYPE_RETRIEVAL_ARGUMENT_GENERATE_COMMUNITY',
    ADS_SEARCH_BUSINESS_PROFILE_BID_KEYWORD_MATCH: 'CLIENT_TYPE_ADS_SEARCH_BUSINESS_PROFILE_BID_KEYWORD_MATCH',
    ADS_SEARCH_BUSINESS_PROFILE: 'CLIENT_TYPE_ADS_SEARCH_BUSINESS_PROFILE',
    RETRIEVAL_ARGUMENT_GENERATE_SEARCH_RECOMMEND: 'CLIENT_TYPE_RETRIEVAL_ARGUMENT_GENERATE_SEARCH_RECOMMEND',
    LLM_SEARCH_WEBVIEW: 'CLIENT_TYPE_LLM_SEARCH_WEBVIEW',
    LLM_SEARCH_OPERATION_KEYWORD_MATCH: 'CLIENT_TYPE_LLM_SEARCH_OPERATION_KEYWORD_MATCH',
    CAR_RELEVANT: 'CLIENT_TYPE_CAR_RELEVANT'
} as const;

export type ApiInternalTestLlmSearchStreamPostRequestClientTypeEnum = typeof ApiInternalTestLlmSearchStreamPostRequestClientTypeEnum[keyof typeof ApiInternalTestLlmSearchStreamPostRequestClientTypeEnum];


