/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { LatLng } from './lat-lng';

/**
 * 
 * @export
 * @interface ApiV1ShortformSearchPostRequest
 */
export interface ApiV1ShortformSearchPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiV1ShortformSearchPostRequest
     */
    'query': string;
    /**
     * 
     * @type {number}
     * @memberof ApiV1ShortformSearchPostRequest
     */
    'pageSize': number;
    /**
     * 
     * @type {string}
     * @memberof ApiV1ShortformSearchPostRequest
     */
    'pageToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1ShortformSearchPostRequest
     */
    'sort'?: ApiV1ShortformSearchPostRequestSortEnum;
    /**
     * 
     * @type {LatLng}
     * @memberof ApiV1ShortformSearchPostRequest
     */
    'location'?: LatLng;
    /**
     * 
     * @type {string}
     * @memberof ApiV1ShortformSearchPostRequest
     */
    'regionId': string;
}

export const ApiV1ShortformSearchPostRequestSortEnum = {
    UNSPECIFIED: 'SHORTFORM_SEARCH_SORT_UNSPECIFIED',
    RELEVANT: 'SHORTFORM_SEARCH_SORT_RELEVANT',
    DISTANCE: 'SHORTFORM_SEARCH_SORT_DISTANCE'
} as const;

export type ApiV1ShortformSearchPostRequestSortEnum = typeof ApiV1ShortformSearchPostRequestSortEnum[keyof typeof ApiV1ShortformSearchPostRequestSortEnum];


