/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { SearchBigPicture } from './search-big-picture';

/**
 * 
 * @export
 * @interface PlaceSchemaComponentPlaceFetch
 */
export interface PlaceSchemaComponentPlaceFetch {
    /**
     * 
     * @type {string}
     * @memberof PlaceSchemaComponentPlaceFetch
     */
    'regionName'?: string;
    /**
     * 
     * @type {SearchBigPicture}
     * @memberof PlaceSchemaComponentPlaceFetch
     */
    'picture'?: SearchBigPicture;
    /**
     * 
     * @type {SearchBigPicture}
     * @memberof PlaceSchemaComponentPlaceFetch
     */
    'bgImage'?: SearchBigPicture;
    /**
     * 
     * @type {boolean}
     * @memberof PlaceSchemaComponentPlaceFetch
     */
    'hasCoupon'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlaceSchemaComponentPlaceFetch
     */
    'operationStatus'?: PlaceSchemaComponentPlaceFetchOperationStatusEnum;
    /**
     * 
     * @type {Array<SearchBigPicture>}
     * @memberof PlaceSchemaComponentPlaceFetch
     */
    'images'?: Array<SearchBigPicture>;
}

export const PlaceSchemaComponentPlaceFetchOperationStatusEnum = {
    UNSPECIFIED: 'OPERATION_STATUS_UNSPECIFIED',
    OPEN: 'OPERATION_STATUS_OPEN',
    CLOSED_FOR_PREPARATION: 'OPERATION_STATUS_CLOSED_FOR_PREPARATION',
    BREAK_TIME: 'OPERATION_STATUS_BREAK_TIME',
    CLOSED_TODAY: 'OPERATION_STATUS_CLOSED_TODAY',
    OPEN_24_HOURS: 'OPERATION_STATUS_OPEN_24_HOURS'
} as const;

export type PlaceSchemaComponentPlaceFetchOperationStatusEnum = typeof PlaceSchemaComponentPlaceFetchOperationStatusEnum[keyof typeof PlaceSchemaComponentPlaceFetchOperationStatusEnum];


