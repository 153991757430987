/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { V2FleamarketSchemaComponentRelatedKeywordKeywordsInner } from './v2-fleamarket-schema-component-related-keyword-keywords-inner';

/**
 * 
 * @export
 * @interface V2FleamarketSchemaComponentRelatedKeyword
 */
export interface V2FleamarketSchemaComponentRelatedKeyword {
    /**
     * 
     * @type {Array<V2FleamarketSchemaComponentRelatedKeywordKeywordsInner>}
     * @memberof V2FleamarketSchemaComponentRelatedKeyword
     */
    'keywords'?: Array<V2FleamarketSchemaComponentRelatedKeywordKeywordsInner>;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentRelatedKeyword
     */
    'template': V2FleamarketSchemaComponentRelatedKeywordTemplateEnum;
    /**
     * 
     * @type {string}
     * @memberof V2FleamarketSchemaComponentRelatedKeyword
     */
    'slot': string;
}

export const V2FleamarketSchemaComponentRelatedKeywordTemplateEnum = {
    UNSPECIFIED: 'RELATED_KEYWORDS_TEMPLATE_UNSPECIFIED',
    TOP: 'RELATED_KEYWORDS_TEMPLATE_TOP',
    FILTER: 'RELATED_KEYWORDS_TEMPLATE_FILTER',
    LIST_VIEW: 'RELATED_KEYWORDS_TEMPLATE_LIST_VIEW'
} as const;

export type V2FleamarketSchemaComponentRelatedKeywordTemplateEnum = typeof V2FleamarketSchemaComponentRelatedKeywordTemplateEnum[keyof typeof V2FleamarketSchemaComponentRelatedKeywordTemplateEnum];


