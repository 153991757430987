/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ProtobufTimestamp } from './protobuf-timestamp';
import { SearchBigPicture } from './search-big-picture';

/**
 * 
 * @export
 * @interface V3IntegrateSearchSuccessResponseRealtyDocsInner
 */
export interface V3IntegrateSearchSuccessResponseRealtyDocsInner {
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'docId': string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'bigpictureSalesImageIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'salesTypeTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'priceTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'areaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'floorTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'manageCostTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'locationTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'targetUri'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'otherSalesTypeTags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'articleStatus'?: V3IntegrateSearchSuccessResponseRealtyDocsInnerArticleStatusEnum;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'createdAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {ProtobufTimestamp}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'publishedAt'?: ProtobufTimestamp;
    /**
     * 
     * @type {SearchBigPicture}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'picture'?: SearchBigPicture;
    /**
     * 
     * @type {boolean}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'isWriterVerified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'pullUpCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'chatCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'voteCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'viewCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof V3IntegrateSearchSuccessResponseRealtyDocsInner
     */
    'index': number;
}

export const V3IntegrateSearchSuccessResponseRealtyDocsInnerArticleStatusEnum = {
    UNSPECIFIED: 'REALTY_STATUS_UNSPECIFIED',
    ON_SALE: 'REALTY_STATUS_ON_SALE',
    RESERVED: 'REALTY_STATUS_RESERVED',
    TRADE_COMPLETED: 'REALTY_STATUS_TRADE_COMPLETED'
} as const;

export type V3IntegrateSearchSuccessResponseRealtyDocsInnerArticleStatusEnum = typeof V3IntegrateSearchSuccessResponseRealtyDocsInnerArticleStatusEnum[keyof typeof V3IntegrateSearchSuccessResponseRealtyDocsInnerArticleStatusEnum];


