/* tslint:disable */
/* eslint-disable */
/**
 * Search BFF swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PlaceSchemaComponent } from './place-schema-component';
import { PlaceSchemaMeta } from './place-schema-meta';

/**
 * 
 * @export
 * @interface PlaceSchema
 */
export interface PlaceSchema {
    /**
     * 
     * @type {string}
     * @memberof PlaceSchema
     */
    'componentType': PlaceSchemaComponentTypeEnum;
    /**
     * 
     * @type {PlaceSchemaComponent}
     * @memberof PlaceSchema
     */
    'component': PlaceSchemaComponent;
    /**
     * 
     * @type {PlaceSchemaMeta}
     * @memberof PlaceSchema
     */
    'meta': PlaceSchemaMeta;
}

export const PlaceSchemaComponentTypeEnum = {
    UNSPECIFIED: 'PLACE_COMPONENT_TYPE_UNSPECIFIED',
    PLACE: 'PLACE_COMPONENT_TYPE_PLACE',
    BANNER: 'PLACE_COMPONENT_TYPE_BANNER',
    AD: 'PLACE_COMPONENT_TYPE_AD'
} as const;

export type PlaceSchemaComponentTypeEnum = typeof PlaceSchemaComponentTypeEnum[keyof typeof PlaceSchemaComponentTypeEnum];


